import React from 'react';
import { Box, Typography } from '@mui/material';

const PlaceholderImage = (width, height) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#e0e0e0',
                color: '#888',
                height: height,
                width: width,
                borderRadius: 2,
                border: '1px solid #ccc',
            }}
        >
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
                Image Not Available
            </Typography>
        </Box>
    );
};

export default PlaceholderImage;
