import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";


const ImageUploadSection = ({ values, setFieldValue, errors, touched, disabled = false }) => (
  <>
    <Typography variant="h6" mb={2}>
      Vehicle Images
    </Typography>
    <ImageUpload
      maxImages={8}
      maxSizeMB={1}
      allowedFormats={['jpeg', 'jpg', 'png', 'gif']}
      value={values.files}
      setFieldValue={setFieldValue}
      // error={!!errors.images && !values.images}
      error={errors.files && touched.files}
      helperText={errors.files && touched.files && values.files.length === 0 ? errors.files : ''}
      disabled={disabled}
    />
  </>
);

export default ImageUploadSection;
