import React, { useState } from 'react';
import { Box, Grid2 as Grid, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Stack } from '@mui/material';
import { MoreVert as MoreVertIcon, AccessTime as AccessTimeIcon } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { Button } from '../../../components';
import { useNavigate } from "react-router-dom";
import { AuctionTimer, TimerProviderWrapper } from '../../../components/Timer';
import PlaceholderImage from '../../../components/PlaceHolderImage';

const Advertisements = ({ adData, role }) => {
    const navigate = useNavigate();
    const currentTime = new Date().toISOString();
    const isConsumer = role === "consumer";
    const sectionTitle = isConsumer ? "ADVERTISEMENTS" : "AUCTION";
    const emptyMessage = isConsumer
        // ? "There are no cars available in the listings."
        ? "You haven't placed any ads yet!"
        : "No auctions are available at the moment.";

    const [openModal, setOpenModal] = useState(false);
    const [currentListingId, setCurrentListingId] = useState(null);

    const handleAdvertisements = (listingId) => {
        if (role === "consumer") {
            navigate(`/ads/${listingId}`);
        }
        if (role === "dealer") {
            navigate(`/auctions/${listingId}`);
        }
        setCurrentListingId(listingId);
        setOpenModal(true);
    };

    const handleModalConfirm = () => {
        setOpenModal(false);
        navigate("/login");
    };

    const handleModalCancel = () => {
        setOpenModal(false);
        setCurrentListingId(null);
    };

    return (

        <Box sx={{ backgroundImage: "linear-gradient(to right, #69CF87 1%, #074519 100%)", padding: "40px 20px", }}>
            <Typography variant="body2" textAlign="center" color="#FFFFFF" fontWeight={'bold'} mb={4} sx={{ fontSize: { xs: 26, sm: 35 } }}>
                {sectionTitle}
            </Typography>
            {adData && adData?.length > 0 ? (
                // console.log('adData', adData),
                <Grid container spacing={2} justifyContent="center">
                    {adData
                        .filter(data => data.status !== 'draft')
                        .slice(0, 4)
                        .map((data) => (
                            // console.log('data', data),
                            <Grid xs={12} sm={6} md={3} key={data.id}>
                                <Box
                                    width={285}
                                    padding={2}
                                    borderRadius={'12px'}
                                    textAlign={"center"}
                                    overflow={"hidden"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"space-between"}
                                    sx={{ backgroundColor: "white", height: { xs: '390px', md: '430px' } }}
                                    boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.1)"}
                                >
                                    {data?.listing_images.length > 0 && (
                                        <Box
                                            component="img"
                                            src={data?.listing_images?.[0]?.url}
                                            alt={data?.makes?.name}
                                            sx={{
                                                width: "100%",
                                                height: "180px",
                                                objectFit: "cover",
                                                // marginBottom: "5px",
                                            }} />
                                    )}
                                    {data?.listing_images.length === 0 && (
                                        <PlaceholderImage width={'auto'} height='180px' />
                                    )}
                                    <Typography variant="p1" fontWeight="bold" >
                                        {data?.vehicle_details?.modelYear} {data?.makes?.name}
                                    </Typography>

                                    <Box
                                        display="grid"
                                        gridTemplateColumns="auto auto"
                                        gap={2}
                                        color="text.secondary"
                                    >
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Icon icon="mdi:gas-station" fontSize={18} style={{ marginRight: 4 }} />
                                            <Typography variant="body4" fontSize={14} color='text.secondary'>
                                                {data?.engine_types?.name}
                                            </Typography>

                                        </Box>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Icon icon="material-symbols:auto-transmission-sharp" fontSize={18} style={{ marginRight: 4 }} />
                                            <Typography variant="body4" fontSize={14} color='text.secondary'>
                                                {data?.transmission_types?.name}
                                            </Typography>
                                        </Box>

                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Icon icon="fa-solid:road" fontSize={16} style={{ marginRight: 4 }} />
                                            <Typography variant="body4" fontSize={14} color='text.secondary'>
                                                {data?.vehicle_details?.mileage} CC
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Icon icon="bxs:user" fontSize={16} style={{ marginRight: 4 }} />
                                            <Typography variant="body4" fontSize={14} color='text.secondary'>
                                                {data?.vehicle_details?.seatCapacity} Passengers
                                            </Typography>
                                        </Box>
                                    </Box>


                                    <Divider sx={{ mt: 1 }} />
                                    <TimerProviderWrapper endTime={data?.endTime}>
                                        {(data?.endTime >= currentTime) && (
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                alignItems="center"
                                                justifyContent="center"
                                            // mt={1}
                                            // marginBottom={{ xs: "10px", md: "0" }}
                                            >
                                                <AccessTimeIcon color="success" fontSize="small" />
                                                <Typography variant="body2">
                                                    <AuctionTimer />
                                                </Typography>
                                            </Stack>
                                        )}
                                    </TimerProviderWrapper>

                                    <Box>
                                        <Button
                                            variant="contained"
                                            color='secondary'
                                            fullWidth
                                            onClick={() => handleAdvertisements(data?.id)}
                                            sx={{ height: '45px', fontSize: '16px' }}
                                        >
                                            View Details
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                </Grid>
            ) : (
                <Typography variant='h6' color='third.secondary' textAlign="center">
                    {emptyMessage}
                </Typography>
            )}

            {/* Confirmation Modal */}
            <Dialog open={openModal} onClose={handleModalCancel}>
                <DialogTitle>Sign In Required</DialogTitle>
                <DialogContent>
                    <Typography variant='body1' color='text.secondary'>
                        {/* You need to login your account to view the details. Would you like to login now? */}
                        To view the details, please sign in to your account. Would you like to sign in now?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'flex-end', padding: '8px 24px 24px' }}>
                    <Button onClick={handleModalCancel} variant="outlined" color="transparent" sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: '16px', height: 'fit-content' }} >
                        Cancel
                    </Button>
                    <Button onClick={handleModalConfirm}
                        sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: '16px', height: 'fit-content' }}>
                        Sign In
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

    );
};

export default Advertisements;
