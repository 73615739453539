import React, { useState, useEffect } from "react";
import { Stack, Box, Button, Card as MuiCard, CardContent, Typography, Divider, useTheme } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Card, TextInput, Toaster, Modal } from "../../../components";
import { dealerBid } from "../../../api/services/dealerService";
import { AuctionTimer, TimerProviderWrapper } from "../../../components/Timer"
import toast from "react-hot-toast";
import { counterNegotiation, acceptNegotiation, rejectNegotiation } from "../../../api/services/negotiationService";
import { useAuth } from "../../../context/AuthContext";
import socket from "../../../config/socket";

const BidInfoCard = ({ data, refreshData }) => {
    const { user } = useAuth();
    const listingId = data?.id;
    const hasDealerBids = data?.dealer_bids?.length > 0;
    const highestBidder = data?.dealer_bids[0]
    const [selectedBid, setSelectedBid] = useState(null);
    const [customBid, setCustomBid] = useState("");
    const [showCustomBidInput, setShowCustomBidInput] = useState(false);
    const [dealerBids, setDealerBids] = useState(data?.dealer_bids || []);
    const [latestHighestBid, setLatestHighestBid] = useState(data?.latestHighestBid || 0);
    const priceIncrements = [50, 100, 150, 200];
    const priceOptions = priceIncrements.map(increment => latestHighestBid + increment);
    const currentTime = new Date().toISOString();
    const [modalOpen, setModalOpen] = useState(false);
    const [price, setPrice] = useState('');
    const negotiation_details = data?.negotiation_details[0];
    const consumerId = data?.user_id;
    const dealerId = highestBidder?.dealer_id;
    const [negotiationUpdates, setNegotiationUpdates] = useState(data?.negotiation_details || []);
    const hasNegotiatingDetails = negotiationUpdates?.length > 0;
    const latest_negotiation_id = negotiationUpdates?.[negotiationUpdates?.length - 1]?.id;
    const latest_negotiation_details = negotiationUpdates?.[negotiationUpdates?.length - 1];
    const theme = useTheme();

    const handlePriceClick = (price) => {
        setSelectedBid(price);
        setShowCustomBidInput(false);
        setCustomBid("");
    };

    const handleCustomBidClick = () => {
        setShowCustomBidInput(true);
        setSelectedBid(null);
    };

    const handleCustomBidChange = (e) => {
        setCustomBid(e.target.value);
    };

    const handleCounterNegotiation = async () => {
        try {
            const response = await Toaster.handleApiCall(
                () => counterNegotiation(data?.id, latest_negotiation_id, price),
                "Negotiation started",
                () => {
                }
            );
        } catch (error) {
            console.error("Error Negotiation:", error);
        }
        refreshData();
        // setModalOpen(false);
        setPrice('');
    }

    const handleAcceptNegotiation = async () => {
        try {
            const response = await Toaster.handleApiCall(
                () => acceptNegotiation(data?.id, latest_negotiation_id),
                "Offer Accepted",
                () => {
                }
            );
        } catch (error) {
            console.error("Error accepting Negotiation:", error);
        }
        refreshData();
        // setModalOpen(false);
        setPrice('');
    }

    const handleRejectNegotiation = async () => {
        try {
            const response = await Toaster.handleApiCall(
                () => rejectNegotiation(data.id, latest_negotiation_id),
                "Offer Rejected",
                () => {

                }
            );
        } catch (error) {
            console.error("Error accepting Negotiation:", error);
        }
        refreshData();
        // setModalOpen(false);
        setPrice('');
    }

    const handleBidPlacing = async () => {
        const bidAmount = showCustomBidInput ? customBid : selectedBid;

        if (data?.endTime < currentTime) {
            toast.dismiss()
            toast.error("The ad has expired and is no longer available.",
                {
                    style: theme.toast.style,
                })
            return
        }

        try {
            const response = await Toaster.handleApiCall(
                () => dealerBid(listingId, bidAmount),
                "Bidding Done",
                () => { }
            );
            refreshData();
            setCustomBid("");
        } catch (error) {
            console.error("Error placing negotiation:", error);
        }
    };
    // const isValidCustomBid = customBid && parseInt(customBid, 10) > latestHighestBid;
    const isValidCustomBid = !isNaN(customBid) && Number(customBid) > latestHighestBid && Number(customBid) > 0;
    const isValidPrice = !isNaN(price) && Number(price) > latestHighestBid && Number(price) > 0;

    // useEffect(() => {
    //     if (listingId) {
    //         socket.emit("join_listing", { listingId });
    //     }
    //     socket.on("new_bid", (data) => {
    //         console.log("New bid received:", data);
    //     });

    //     return () => {
    //         socket.off("new_bid");
    //     };
    // }, [listingId]);

    useEffect(() => {
        if (data) {
            setDealerBids(data?.dealer_bids || []);
            setLatestHighestBid(data?.latestHighestBid || 0);
            setNegotiationUpdates(data?.negotiation_details)
        }
    }, [data]);

    useEffect(() => {
        if (listingId) {
            socket.emit("join_listing", { listingId });

            socket.on("new_bid", (newBid) => {
                console.log("New bid received:", newBid);

                if (newBid.dealer_id === user.id) {
                    setDealerBids((prevBids) => [newBid, ...prevBids]);
                }

                setLatestHighestBid((prevHighest) =>
                    Math.max(prevHighest, newBid.amount)
                );
            });
        }

        return () => {
            socket.off("new_bid");
        };
    }, [listingId, user.id]);


    useEffect(() => {
        if (consumerId && dealerId) {
            // Join negotiation event
            socket.emit("join_negotiation", { consumerId, dealerId });
            console.log(consumerId, dealerId);


            // Listen for negotiation updates
            socket.on("negotiation_update", (update) => {
                console.log("Negotiation update received:", update);

                if (update?.dealerId === dealerId) {
                    // setNegotiationUpdates((prevUpdates) => [update, ...prevUpdates]);
                    setNegotiationUpdates((prevUpdates) => [...prevUpdates, update.dataValues]);
                    // refreshData();
                }
                else if (update?.dataValues?.bid_id === highestBidder?.id) {
                    // refreshData();
                    setNegotiationUpdates((prevUpdates) => {
                        const isDuplicate = prevUpdates.some(
                            (item) => JSON.stringify(item) === JSON.stringify(update.dataValues)
                        );

                        if (!isDuplicate) {
                            // Remove the last item and append the new dataValues
                            const updatedArray = [...prevUpdates];
                            updatedArray.pop(); // Remove the last item
                            updatedArray.push(update.dataValues); // Add the new item at the end
                            return updatedArray;
                        }

                        return prevUpdates;
                    });
                }
            });
        }

        return () => {
            socket.off("negotiation_update");
        };
    }, [listingId]);

    return (
        <TimerProviderWrapper endTime={data?.endTime}>
            <Box flex="1">
                <Stack direction="row" spacing={1} alignItems="center" mb={2}>
                    <AccessTimeIcon color="success" fontSize="small" />
                    <Typography variant="body2">
                        {/* {data?.auctionDuration} remaining */}
                        <AuctionTimer />
                    </Typography>
                </Stack>

                <Stack mb={1}>
                    <Typography variant="h8" fontWeight="bold">
                        {data?.vehicle_details?.modelYear}  {data?.makes?.name}
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="body6">Reserved Price</Typography>
                    <Typography variant="h7">
                        USD {data?.reservedPrice}
                    </Typography>
                </Stack>

                <Divider sx={{ my: 4 }} />

                <MuiCard
                    sx={{
                        bgcolor: "third.secondary",
                        borderRadius: "10px",
                        boxShadow: "none",
                        padding: 3
                    }}
                >
                    <CardContent style={{ paddingBottom: "8px" }}>
                        <Stack spacing={3} alignItems="center">
                            <Stack direction="row" justifyContent="space-evenly" width="100%">
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={1}>
                                    <Typography variant="h10" fontWeight="bold" color="primary.main">Highest Bid</Typography>
                                    <Typography variant="h7">${latestHighestBid || 0}</Typography>
                                </Box>
                                <Divider orientation="vertical" flexItem />
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={1}>
                                    <Typography variant="h10" fontWeight="bold" color="third.primary">Bid Made</Typography>
                                    <Typography variant="h7">{dealerBids?.length || 0}</Typography>
                                </Box>
                            </Stack>

                            <Stack direction="row" alignItems="center" spacing={1}>
                                <AccessTimeIcon color="success" fontSize="small" />
                                <Typography variant="body2">
                                    {/* {data?.auctionDuration} remaining */}
                                    <AuctionTimer />
                                </Typography>
                            </Stack>
                        </Stack>
                    </CardContent>
                </MuiCard>

                {hasDealerBids ? (
                    <Box display="flex" flexDirection="column" alignItems="center" mt={2} >
                        {dealerBids.map((bid, index) => (
                            <Box
                                key={index} display="flex" alignItems="center" position="relative" mb="30px" width={'100%'}
                            // sx={{ marginBottom: index < data.dealer_bids.length - 1 ? 2 : 0 }}
                            >
                                < Box display="flex" flexDirection="column" alignItems="center" mr={2}>
                                    <Box width="16px" height="16px" borderRadius="50%" bgcolor="primary.main" border="3px solid #DEDEDE" zIndex={1} />
                                    {index < dealerBids.length - 1 && (
                                        <Box width="3px" height="60px" bgcolor="#DEDEDE" position="absolute" />
                                    )}
                                </Box>
                                < Typography variant="h11"> ${bid.amount}</Typography>
                                <Box ml={'auto'}>
                                    {index === 0 && (
                                        <Box>
                                            {highestBidder?.status === 'accepted' ? (
                                                <Button variant="containedAccepted">
                                                    Bid Accepted
                                                </Button>
                                            ) : (
                                                hasNegotiatingDetails && (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => {
                                                            setModalOpen(true);
                                                        }}
                                                    >
                                                        View Negotiation
                                                    </Button>
                                                )
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </Box >
                ) : (
                    <Typography variant="h7" textAlign="center" display="flex" justifyContent="center" paddingY="16px" >
                        You Haven't Made Any Bid Yet.
                    </Typography>
                )}
            </Box >

            {(!data?.closedAmount) ? (
                <Card sx={{
                    bgcolor: "third.secondary",
                    borderRadius: "10px",
                    boxShadow: "none",
                }}>

                    <CardContent>
                        {/* Price Selection */}
                        <Stack direction="row" justifyContent="center" flexWrap="wrap" mb={2}>
                            {priceOptions.map((price, index) => (
                                <Button
                                    key={index}
                                    variant={selectedBid === price ? "contained" : "outlined"}
                                    color="secondary"
                                    onClick={() => handlePriceClick(price)}
                                    sx={{ mr: '1px' }}
                                >
                                    ${price}
                                </Button>
                            ))}
                            <Button
                                variant={showCustomBidInput ? "contained" : "outlined"}
                                color="secondary"
                                onClick={handleCustomBidClick}
                            >
                                Custom Bid
                            </Button>
                        </Stack>

                        {/* Custom Bid Input */}
                        {showCustomBidInput && (
                            <TextInput
                                fullWidth
                                name="customBid"
                                type="number"
                                variant="outlined"
                                label="Enter Custom Bid"
                                value={customBid}
                                onChange={handleCustomBidChange}
                                helperText={customBid && !isValidCustomBid &&
                                    `Your negotiation must be higher than the current $${latestHighestBid} negotiation value.`}
                                // `Custom negotiation must be greater than $${latestHighestBid}`}
                                error={customBid && !isValidCustomBid}
                            />
                        )}
                    </CardContent>

                    <Button
                        variant="contained"
                        fullWidth
                        sx={{ textTransform: 'capitalize' }}
                        disabled={
                            (showCustomBidInput && !isValidCustomBid) ||
                            (!showCustomBidInput && selectedBid === null)
                        }
                        onClick={handleBidPlacing}
                    >
                        Place Bid for ${showCustomBidInput ? customBid : selectedBid}
                    </Button>
                </Card>
            ) : (
                <MuiCard
                    sx={{
                        bgcolor: "third.secondary",
                        borderRadius: "10px",
                        boxShadow: "none",
                        mt: "5%"
                    }}
                >
                    <CardContent style={{ paddingBottom: "8px" }}>
                        <Box display="flex" flexDirection="column" gap={2} marginBottom={1}>
                            <Typography variant="h7">
                                Accepted Offer Details
                            </Typography>

                            <Typography variant="body8" fontSize={14}>
                                Deal Confirmed on: <Typography variant="p4">${data?.closedAmount}</Typography>
                            </Typography>
                        </Box>
                    </CardContent>
                </MuiCard>
            )}

            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                    setPrice('')
                }
                }
                width={{ xs: '90%', sm: '50%', md: '40%', lg: '30%' }}
            >
                <Typography variant="h5">Add Your Offer</Typography>
                <Box display="flex" flexDirection="column" mt={5}>
                    <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Box display="flex" flexDirection="column" alignItems="center" >
                            {negotiationUpdates.map((negotiation, index) => (
                                <Box key={index} display="flex" alignItems="center" position="relative" mb="30px" width={'100%'}>
                                    <Typography variant="p4" width={70} display={'flex'} justifyContent={'center'}>
                                        {negotiation.initiator_id === user?.id ? "You" : "Consumer"}
                                    </Typography>
                                    <Box display="flex" flexDirection="column" alignItems="center" ml={1} mr={1}>
                                        <Box width="16px" height="16px" borderRadius="50%" bgcolor="primary.main" border="3px solid #DEDEDE" zIndex={1} />
                                        {index < negotiationUpdates.length - 1 && (
                                            <Box width="3px" height="60px" bgcolor="#DEDEDE" position="absolute" />
                                        )}
                                    </Box>

                                    <Typography variant="h11" mr={5}>
                                        ${negotiation?.offerAmount}
                                        {negotiation.status !== 'ongoing' && (
                                            <Typography variant="p4" ml={'10px'} textTransform="capitalize" color={negotiation.status === 'accepted' ? 'primary.main' : 'error.main'}>
                                                ({negotiation.status})
                                            </Typography>
                                        )}
                                    </Typography>

                                    {/* Only show Accept button for specific conditions */}
                                    {(latest_negotiation_details?.status === 'ongoing' &&
                                        ((index === 0 && negotiationUpdates.length === 1))) && (
                                            // (index === 2 && negotiationUpdates.length === 3))) && (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleAcceptNegotiation}
                                                sx={{ ml: 'auto', width: 'auto', fontSize: '14px' }}
                                            >
                                                Accept
                                            </Button>
                                        )}
                                </Box>
                            ))}
                        </Box>
                    </Stack>


                    {/* Show input and buttons only if the latest negotiation status is ongoing */}
                    {latest_negotiation_details?.status === 'ongoing' && (
                        <>
                            {negotiationUpdates?.length <= 1 && (
                                <Stack spacing={1} mt={'10%'}>
                                    <Typography variant="p1">Add your price (Usd)</Typography>
                                    <TextInput
                                        name={price}
                                        value={price}
                                        type="number"
                                        label="Please Enter"
                                        fullWidth
                                        onChange={(e) => setPrice(e.target.value)}
                                        helperText={
                                            price &&
                                            !isValidPrice &&
                                            `The price must be higher than $${latestHighestBid} bid value.`
                                        }
                                        error={price && !isValidPrice}
                                    />
                                </Stack>
                            )}
                            <Stack mt={3} flexDirection="row" justifyContent="flex-end" gap="20px">
                                {negotiationUpdates?.length <= 2 ? (
                                    <Button
                                        variant="contained"
                                        color="success"
                                        sx={{ width: '120px', height: 'fit-content', textTransform: 'capitalize', backgroundColor: "primary.main" }}
                                        onClick={handleCounterNegotiation}
                                        disabled={!isValidPrice}

                                    >
                                        Negotiate
                                    </Button>
                                ) : (
                                    <Stack flexDirection="row" gap={1}>
                                        <Button
                                            variant="contained"
                                            // color="secondary"
                                            // sx={{ width: '120px', fontSize: '14px', backgroundColor: 'error.main' }}
                                            color="success"
                                            sx={{ width: { xs: 'auto', sm: '120px' }, height: 'fit-content', textTransform: 'capitalize', backgroundColor: "error.main" }}
                                            onClick={handleRejectNegotiation}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            variant="contained"
                                            // color="secondary"
                                            // sx={{ width: '120px', fontSize: '14px', backgroundColor: 'error.main' }}
                                            color="success"
                                            sx={{ width: { xs: 'auto', sm: '120px' }, height: 'fit-content', textTransform: 'capitalize' }}
                                            onClick={handleAcceptNegotiation}
                                        >
                                            Accept
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>
                        </>
                    )}

                    {/* Show this if no negotiation yet */}
                    {negotiationUpdates?.length === 0 && (
                        <Typography variant="h7" textAlign="center" display="flex" justifyContent="center" paddingY="16px">
                            You Haven't Made Any Negotiation Yet.
                        </Typography>
                    )}
                </Box>

            </Modal>
        </TimerProviderWrapper>
    );
};

export default BidInfoCard;

