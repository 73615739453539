import React, { useState, useEffect } from "react";
import { Button, Container, Typography, Stack, Box } from "@mui/material";
import AdViewCard from "../../../components/AdViewCard";
import { dealerBidAd } from "../../../api/services/dealerService";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader"

const MyBids = () => {
  const [adData, setAdData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleFetchAd = async () => {
    try {
      const response = await dealerBidAd();
      // setAdData(response);
      const data = response?.data || [];
      console.log("dealer bid ad api response", data);
      setAdData(data);
    } catch (error) {
      console.error("Error fetching ads:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePlaceBid = () => {
    navigate("/auctions");
  };

  useEffect(() => {
    handleFetchAd();
  }, []);


  return (
    <>
      <Container sx={{ py: {xs: 0, md: 4}, alignItems: 'start' }}>
        <Box
          bgcolor={'#fff'} borderRadius={'12px'} boxShadow={'0px 4px 12px rgba(0, 0, 0, 0.1)'} padding={{ xs: 2, md: 4 }} width={'100%'}
        >
          {loading ? (
            <Loader />
          ) : adData && adData?.length > 0 ? (
            <Stack spacing={2}>
              <Stack>
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
                  My Bids
                </Typography>
              </Stack>
              {adData?.map((data, index) => (
                <AdViewCard
                  key={index}
                  userId={data?.user_id}
                  route={`/bids/${data.id}`}
                  listingId={data?.id}
                  image={data?.listing_images?.[0]}
                  modelYear={data?.vehicle_details?.modelYear}
                  title={data?.makes.name}
                  subtitle="Reserved Price"
                  price={`USD ${data?.reservedPrice || "0"}`}
                  details={[
                    { key: "engine", icon: "mdi:engine", value: data?.vehicle_details?.engineCapacity },
                    { key: "mileage", icon: "mdi:road", value: data?.vehicle_details?.mileage },
                    { key: "fuel", icon: "mdi:gas-station", value: data?.engine_types?.name },
                    { key: "drive", icon: "material-symbols:auto-transmission-sharp", value: data?.transmission_types?.name },
                  ]}
                  time={data?.auctionDuration}
                  status={data?.status}
                  // menuItems={getMenuItems(data?.status)}
                  handleFetchAd={handleFetchAd}
                  endTime={data?.endTime}
                />
              ))}
            </Stack>
          ) : (
            <Stack
              alignItems="center"
              spacing={2}
              sx={{ textAlign: "center", py: 4 }}
            >
              <Typography variant="h6" color="textSecondary">
                No Bids Yet? Get Started Now!
              </Typography>
              <Typography color="textSecondary">
                Explore exciting auctions and place your first bid today!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePlaceBid}
                sx={{ mt: 2, textTransform: 'capitalize' }}
              >
                Start Exploring Auctions
              </Button>
            </Stack>
          )}
        </Box>
      </Container>
    </>
  );
};

export default MyBids;
