import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid2 as Grid, Container, Tab, Tabs, InputAdornment, } from '@mui/material';
import { Search } from '@mui/icons-material';
import Table from '../../../components/Table';
import { fetchAllUsers, updateUserStatus } from '../../../api/services/adminService';
import { Button, TextInput } from '../../../components';

const AdminUsersPage = () => {
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const columns = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'address', label: 'Address', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'user_role', label: 'Role', align: 'center', render: (value) => value?.name || 'N/A' },
    { id: 'phoneNo', label: 'Phone Number', align: 'center' },
    {
      id: 'actions',
      label: 'Actions',
      align: 'center',
      render: (_, row) => (
        <Box display="flex" justifyContent="center" gap={1}>
          <Button
            variant="contained"
            color={row.isActive ? 'error' : 'success'}
            disabled={loading}
            onClick={() => handleStatusChange(row.id, !row.isActive)}
            sx={{ width: 'auto', height: 'auto', textTransform: 'capitalize' }}
          >
            {row.isActive ? 'InActive' : 'Active'}
          </Button>
        </Box>
      ),
    },
  ];

  const fetchData = async (userType = 'consumer') => {
    try {
      const response = await fetchAllUsers(userType);
      const data = response?.data?.data || [];
      setUserData(data);
      setFilteredData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData('consumer');
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchQuery("");
    if (newValue === 0) {
      fetchData('consumer');
    } else if (newValue === 1) {
      fetchData('dealer');
    }
  };

  const handleStatusChange = async (userId, newStatus) => {
    setLoading(true);
    try {
      await updateUserStatus(userId, newStatus);
      setUserData((prevData) =>
        prevData.map((user) =>
          user.id === userId ? { ...user, isActive: newStatus } : user
        )
      );
      setFilteredData((prevData) =>
        prevData.map((user) =>
          user.id === userId ? { ...user, isActive: newStatus } : user
        )
      );
    } catch (error) {
      console.error('Error updating user status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter userData based on name or email
    const filtered = userData.filter(
      (user) =>
        user.name.toLowerCase().includes(query) || user.email.toLowerCase().includes(query)
    );

    setFilteredData(filtered);
  };

  return (
    <Container sx={{ py: {xs: 0, md: 4}, alignItems: 'start' }}>
      <Paper elevation={3} sx={{ p: 3, width: '100%', maxHeight: '100vh', overflow: 'hidden', }}>

        <Grid container justifyContent="space-between" alignItems="center" gap={2} sx={{ mb: 2 }}>
          <Grid>
            <Typography variant="h4" component="h1" lineHeight={'24px'}>
              User Management
            </Typography>
          </Grid>

          <Grid>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              sx={{ border: "2px solid #22B14B", borderRadius: '7px', overflow: "hidden", minHeight: "35px" }}
            >
              <Tab label="Consumers" sx={{ fontSize: "16px", borderBottom: "0px !important", minHeight: "35px" }} />
              <Tab label="Dealers" sx={{ fontSize: "16px", borderBottom: "0px !important", minHeight: "35px" }} />
            </Tabs>
          </Grid>

          <Grid>
            <TextInput
              label="Search by name or email"
              variant="outlined"
              size="small"
              name={searchQuery}
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

        </Grid>

        <Table columns={columns} rows={filteredData} />
      </Paper>
    </Container>
  );
};

export default AdminUsersPage;