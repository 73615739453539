import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
const AuthLayout = ({ role }) => {

    return (
        <>
            <Outlet />
        </>
    );
};

export default AuthLayout;
