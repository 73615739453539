import * as React from "react";
import { AppBar, Box, Toolbar, IconButton, ImageListItem, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiButton from "@mui/material/Button";
import { Button, } from "../../index";
import logo from "../../../assets/logo/logo.svg";
import { useNavigate } from "react-router-dom";
import { MobileMenu } from "../Menu";
import { useState } from "react";

const PublicNavbar = () => {
    const navigate = useNavigate()
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const pages = [
        { name: "Home", route: "/" },
        { name: "About", route: "/about" },
        { name: "Contact Us", route: "/contactus" },
    ];

    const handleMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleNavigation = (pageName) => {
        const selectedPage = pages.find((page) => page.name === pageName);
        if (selectedPage) {
            navigate(selectedPage.route);
        }
        handleMenuClose();
    };


    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{
                flexGrow: 1,
                // boxShadow: { xs: 'inherit', md: "none" },
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
                backgroundColor: "#ffffff !important", paddingTop: { xs: '5px', md: '0px' }
            }}>
                <Toolbar sx={{ paddingLeft: '0px', paddingRight: '10px' }}>
                    <IconButton
                        size="large"
                        color="inherit"
                        onClick={handleMenuOpen}
                        sx={{ display: { xs: "flex", md: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <ImageListItem sx={{ width: { xs: '88px', sm: 'auto', maxWidth: 200 } }}>
                        <img src={logo} alt="Get A Bid Logo" />
                    </ImageListItem>

                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "end", gap: "30px" }}>
                        {pages.map((page) => (
                            <MuiButton
                                key={page.name}
                                onClick={() => handleNavigation(page.name)}
                                color="secondary"
                                sx={{ my: 2, display: "block", fontFamily: "Nunito", fontSize: '18px' }}
                            >
                                {page.name}
                            </MuiButton>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Stack direction="row" spacing={0.5} >
                        <Button
                            onClick={() => navigate('/login')}
                            // sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: { xs: '12px', sm: '16px' }, height: { xs: 'auto', sm: 'auto' }, backgroundColor: { xs: "transparent", sm: '#22B14B' }, color: { xs: 'black', sm: '#FFFFFF' }, padding: { xs: '0px', sm: '12px' }, marginRight: '24px' }}
                            sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: '16px', height: 'fit-content' }}
                        >
                            Sign In
                        </Button>
                        <Button
                            onClick={() => navigate('/signup')}
                            // sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: { xs: '12px', sm: '16px' }, height: { xs: 'auto', sm: 'auto' }, backgroundColor: { xs: "transparent", sm: '#22B14B' }, color: { xs: 'black', sm: '#FFFFFF' }, padding: { xs: '0px', sm: '12px' } }}
                            sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: '16px', height: 'fit-content' }}
                        >
                            Join now
                        </Button>
                    </Stack>
                </Toolbar>
            </AppBar>

            <MobileMenu
                mobileMoreAnchorEl={mobileMoreAnchorEl}
                handleMenuClose={handleMenuClose}
                handleNavigation={handleNavigation}
                pages={pages}
            />

            <Box sx={{ marginTop: '100px' }} />
        </Box>
    );
};

export default PublicNavbar;
