import React, { useState, useEffect } from "react";
import { Container, Stack, Box, Typography } from "@mui/material";
import AdViewCard from "../../../components/AdViewCard";
import { Icon } from "@iconify/react";
import FilterSidebar from "../../../components/FilterSidebar";
import Grid from "@mui/material/Grid2";
import { Button, Loader } from "../../../components";
import Drawer from "../../../components/Drawer";
import { fetchAuction } from "../../../api/services/dealerService";

const Auctions = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [adData, setadData] = useState([]);
    const [filteredCars, setFilteredCars] = useState([]);
    const [loading, setLoading] = useState(true);


    // State for filters
    const [selectedMakes, setSelectedMakes] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedTransmissions, setSelectedTransmissions] = useState([]);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [priceRange, setPriceRange] = useState([0, 100000]);

    const handleFetchAuction = async () => {
        try {
            const response = await fetchAuction();
            const data = response?.data || [];
            console.log(data)
            setadData(data);
            setFilteredCars(data);

            if (data.length > 0) {
                setPriceRange([
                    Math.min(...data.map(data => data.reservedPrice)),
                    Math.max(...data.map(data => data.reservedPrice)),
                ]);
            }
        } catch (error) {
            console.error("Error fetching ads:", error);
        } finally {
            setLoading(false);
        }
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setIsDrawerOpen(open);
    };

    useEffect(() => {
        handleFetchAuction();
    }, []);

    // Filtering logic
    useEffect(() => {
        const filtered = adData.filter(data => {
            const matchesMake = selectedMakes.length === 0 || selectedMakes.includes(data.makes.id);
            const matchesModel = selectedModels.length === 0 || selectedModels.includes(data.models.id);
            const matchesTransmission = selectedTransmissions.length === 0 || selectedTransmissions.includes(data.transmission_types.name);
            const matchesFeatures = selectedFeatures.length === 0 || selectedFeatures.every(feature => data.features.some(f => f.id === feature));
            const matchesPrice = data.reservedPrice >= priceRange[0] && data.reservedPrice <= priceRange[1];

            return matchesMake && matchesModel && matchesTransmission && matchesFeatures && matchesPrice;
        });
        setFilteredCars(filtered);
    }, [selectedMakes, selectedModels, selectedTransmissions, selectedFeatures, priceRange, adData]);

    // Prepare filter options
    const makeOptions = [...new Map(adData.map(data => [data.makes.id, {
        value: data.makes.id,
        label: data.makes.name,
        count: adData.filter(c => c.makes.id === data.makes.id).length
    }])).values()];

    const modelOptions = [...new Map(adData.map(data => [data.models.id, {
        value: data.models.id,
        label: data.models.name
    }])).values()];

    const transmissionOptions = [...new Map(adData.map(data => [data.transmission_types.id, {
        value: data.transmission_types.name,
        label: data.transmission_types.name
    }])).values()];

    const featuresOptions = Array.from(
        new Map(
            adData
                .flatMap(data => data.features)
                .map(feature => [feature.id, { value: feature.id, label: feature.name }])
        ).values()
    );

    // Handlers for price slider
    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue);
    };

    return (
        // <Container
        //     // maxWidth="auto"
        //     style={{ marginTop: "100px", minHeight: "auto" }}
        // >
        <Container sx={{ py: { xs: 0, md: 4 }, minHeight: "auto" }}>
            <Stack display="flex" alignItems="start" direction="row" width="100%">
                <Grid container sx={{ width: "100%" }}>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <Stack display={{ xs: "none", md: "block" }}>
                            <FilterSidebar
                                makeOptions={makeOptions}
                                modelOptions={modelOptions}
                                transmissionOptions={transmissionOptions}
                                featuresOptions={featuresOptions}
                                priceRange={priceRange}
                                selectedMakes={selectedMakes}
                                setSelectedMakes={setSelectedMakes}
                                selectedModels={selectedModels}
                                setSelectedModels={setSelectedModels}
                                selectedTransmissions={selectedTransmissions}
                                setSelectedTransmissions={setSelectedTransmissions}
                                selectedFeatures={selectedFeatures}
                                setSelectedFeatures={setSelectedFeatures}
                                handlePriceChange={handlePriceChange}
                            />
                        </Stack>
                        <Stack display={{ xs: "flex", md: "none" }} mb={2} justifyContent={"end"} direction={"row"}>
                            <Drawer
                                button={
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{ width: "auto", height: "auto", backgroundColor: "#262626" }}
                                        startIcon={<Icon icon="mdi:filter" style={{ color: "white", fontSize: "20px" }} />}
                                        onClick={toggleDrawer(true)}
                                    >
                                        Add Filter
                                    </Button>
                                }
                            >
                                <FilterSidebar
                                    makeOptions={makeOptions}
                                    modelOptions={modelOptions}
                                    transmissionOptions={transmissionOptions}
                                    featuresOptions={featuresOptions}
                                    priceRange={priceRange}
                                    selectedMakes={selectedMakes}
                                    setSelectedMakes={setSelectedMakes}
                                    selectedModels={selectedModels}
                                    setSelectedModels={setSelectedModels}
                                    selectedTransmissions={selectedTransmissions}
                                    setSelectedTransmissions={setSelectedTransmissions}
                                    selectedFeatures={selectedFeatures}
                                    setSelectedFeatures={setSelectedFeatures}
                                    handlePriceChange={handlePriceChange}
                                />
                            </Drawer>
                        </Stack>
                    </Grid>
                    <Grid size={{ xs: 12, md: 9 }}>
                        <Box width={"100%"} sx={{ ml: { xs: 0, md: 1 } }}>
                            <Stack spacing={2}>
                                <Stack>
                                    {loading ? (
                                        <Loader />
                                    ) : filteredCars?.length > 0 ? (
                                        filteredCars.map((data) => (
                                            <AdViewCard
                                                key={data?.id}
                                                userId={data?.user_id}
                                                route={`/auctions/${data?.id}`}
                                                listingId={data?.id}
                                                image={data?.listing_images?.[0]}
                                                modelYear={data?.vehicle_details?.modelYear}
                                                title={data?.makes.name}
                                                subtitle="Starting Bid"
                                                price={`USD ${data?.reservedPrice}`}
                                                details={[
                                                    { key: "engine", icon: "mdi:engine", value: data?.vehicle_details?.engineCapacity },
                                                    { key: "mileage", icon: "fa-solid:road", value: data?.vehicle_details?.mileage },
                                                    { key: "fuel", icon: "mdi:gas-station", value: data?.engine_types?.name },
                                                    { key: "drive", icon: "material-symbols:auto-transmission-sharp", value: data?.transmission_types?.name },
                                                ]}
                                                time={data?.auctionDuration}
                                                status={data?.status}
                                                endTime={data?.endTime}
                                            />
                                        ))) : (
                                        <Box
                                            sx={{
                                                textAlign: "center",
                                                padding: "20px",
                                                border: "1px solid #ddd",
                                                borderRadius: "8px",
                                                backgroundColor: "#f9f9f9",
                                            }}
                                        >
                                            <Typography variant="h6" color="textSecondary">
                                                No auctions are available at the moment.
                                            </Typography>
                                        </Box>
                                    )}
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    );
};

export default Auctions;