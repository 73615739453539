import React from 'react';
import { Box, Typography, Button, Grid2 as Grid, Stack } from '@mui/material';
import Image from "../../../assets/images/SignUpImage.png";
import { useNavigate } from 'react-router-dom';

const SignUpSection = () => {
    const navigate = useNavigate();
    return (
        <>
            <Box sx={{ backgroundColor: 'background.first', }}>
                <Grid container spacing={0} alignItems={"center"}>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                        sx={{ display: { xs: 'none', md: 'flex' },  }}
                    >
                        <Box
                            component="img"
                            src={Image}
                            alt="SignUp Image"
                            sx={{
                                width: '100%',
                                height: '650px',
                                objectFit: 'cover',
                            }}
                        />
                    </Grid>


                    <Grid size={{ xs: 12, md: 6 }} sx={{ padding: { xs: '40px 20px', md: '0 80px 0 80px' } }}>
                        <Stack spacing={2} justifyContent="flex-start" alignItems="flex-start">
                            <Typography
                                fontSize={'32px'}
                                fontWeight="800"
                                color="text.primary"
                                mb={3}
                                fontFamily={'Nunito'}
                            >
                                READY TO SIGN UP?
                            </Typography>

                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="text.fourth"
                                mb={1}
                            >
                                Higher Profits
                            </Typography>
                            <Typography variant="body1" color="text.secondary" mb={2}>
                                GetABid enables the buyers to profit $316 more per vehicle sourced
                                on GetABid due to our thorough inspection and data.
                            </Typography>

                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="text.fourth"
                                mb={1}
                            >
                                Less Time
                            </Typography>
                            <Typography variant="body1" color="text.secondary" mb={2}>
                                By using GetABid’s wholesale solutions, dealers report saving 20
                                hours per month on average. That's less time out of the office
                                and more time focusing on your customers.
                            </Typography>

                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="text.fourth"
                                mb={1}
                            >
                                More Cars
                            </Typography>
                            <Typography variant="body1" color="text.secondary" mb={4}>
                                GetABid consumer solutions help dealer partners buy an average of
                                15 more vehicles per month from consumers.
                            </Typography>

                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={()=>{navigate('/login')}}
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    height: '40px',
                                    width: '200px'
                                }}
                            >
                                Get started →
                            </Button>
                        </Stack>
                    </Grid>


                </Grid>
            </Box>
        </>
    );
};

export default SignUpSection;
