import React from 'react';
import { Box, Stack, Typography, Grid2 as Grid } from '@mui/material';
import { Button } from '../../../components';
import { Icon } from "@iconify/react";
import Car from '../../../assets/images/Car_jeep.png';

const HeroSection = () => {
    return (
        <>
            {/* First Section */}
            < Grid container spacing={0} alignItems="center" mb={2}>
                <Grid size={{ xs: 12, md: 6 }} sx={{ padding: { xs: '0px 20px 40px 20px ', md: '0 0 0 100px' } }}>
                    <Stack spacing={2} justifyContent="center" alignItems="left">
                        <Typography variant="h2" sx={{fontSize: {xs: '36px', sm: 55}}}>
                            Find, Buy and <br /> Sell a car <span style={{ color: '#21B14B' }}>Easily</span>
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#272727' }}>
                            Get a car wherever and whenever you <br /> need it with your iOS and Android device.
                        </Typography>
                        <Grid
                            size={{ xs: 12, md: 12 }}
                            gap={'10px'}
                            display={'flex'}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            width={'fit-content'}
                        >
                            <Button color="secondary" sx={{ height: 'auto' }}>
                                <Icon icon="ri:apple-fill" style={{ fontSize: '45px', marginRight: '10px', color: 'white' }} />
                                <Stack sx={{ textAlign: 'left' }}>
                                    <Typography variant="p8" fontWeight={'500'}>
                                        Download on the
                                    </Typography>
                                    <Typography fontWeight="bold" color="white" fontSize={"16px"}>
                                        App Store
                                    </Typography>
                                </Stack>
                            </Button>
                            <Button color="secondary" sx={{ height: 'auto' }}>
                                <Icon icon="logos:google-play-icon" style={{ fontSize: '40px', marginRight: '10px' }} />
                                <Stack sx={{ lineHeight: '26px', textAlign: 'left' }}>
                                    <Typography variant="p8" fontWeight={'500'}>
                                        Get it on the
                                    </Typography>
                                    <Typography fontWeight="bold" color="white" fontSize={"16px"}>
                                        Google Play
                                    </Typography>
                                </Stack>
                            </Button>
                        </Grid>
                    </Stack>
                </Grid>
                <Grid
                    size={{ xs: 12, md: 6 }}
                    sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center' }}
                >
                    <Box
                        component="img"
                        src={Car}
                        alt="Car"
                        sx={{
                            maxWidth: '100%',
                            height: 'auto',
                            width:'100%',
                            objectFit: 'contain',
                        }}
                    />
                </Grid>
            </Grid >
        </>
    );
};

export default HeroSection;
