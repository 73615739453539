import * as React from "react";
import { Formik } from 'formik';
import Options from './data.json'
import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useParams, useNavigate } from "react-router-dom";
import { uploadFile } from '../../../api/services/uploadService';
import { viewAd, updateAd } from "../../../api/services/consumerService";
import { Stack, Box, Typography, Container, Grid2 as Grid, useTheme } from "@mui/material";
import { Button, TextInput, Checkbox, Toaster } from "../../../components";
import { getMake, getModelByMakeId, getTransmissionTypes, getBodyTypes, getEngineTypes, getFeatures } from "../../../api/services/listingService";
import { ImageUploadSection, PricingSection, OwnershipVerificationSection, VehicleDetailsSection, VehicleConditionSection } from '../../../components/Form';
import validationSchema from './ValidationSchema';

const EditAd = () => {
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelptions] = useState([]);
    const [transmissionOptions, setTransmissionOptions] = useState([]);
    const [bodyTypeOptions, setBodyTypeOptions] = useState([]);
    const [engineTypeOptions, setEngineTypeOptions] = useState([]);
    const [featuresOptions, setFeaturesOptions] = useState([]);
    const [adData, setAdData] = useState(null);
    const { listingId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();

    const handleBackClick = () => {
        navigate(-1);
    };

    const initialValues = adData ? {
        files: adData?.listing_images || [],
        vehicleRegistration: adData?.vehicle_details?.vehicleRegistration ? [adData?.vehicle_details?.vehicleRegistration] : [],
        driversLicense: adData?.driversLicense ? [adData?.driversLicense] : [],
        reservedBid: adData?.reservedPrice || '',
        startingBid: adData?.startingBid || '',
        modelYear: adData?.vehicle_details?.modelYear || '',
        make: adData?.makes?.id || '',
        model: adData?.models?.id || '',
        transmission: adData?.transmission_types?.id || '',
        engineType: adData?.engine_types?.id || '',
        bodyType: adData?.body_types?.id || '',
        mileage: adData?.vehicle_details?.mileage || '',
        location: adData?.location || '',
        engineCapacity: adData?.vehicle_details?.engineCapacity || '',
        seatCapacity: adData?.vehicle_details?.seatCapacity || '',
        description: adData?.description || '',
        exteriorColor: adData?.vehicle_details?.exteriorColor || '',
        interiorColor: adData?.vehicle_details?.interiorColor || '',
        loanOrLeaseStatus: adData?.vehicle_details?.loanOrLeaseStatus || '',
        companyName: adData?.vehicle_details?.companyName || '',
        dueAmount: adData?.vehicle_details?.dueAmount || '',
        exteriorDamagesStatus: adData?.vehicle_details?.exteriorDamage?.length > 0 ? "yes" : "no",
        exteriorDamage: adData?.vehicle_details?.exteriorDamage ? adData.vehicle_details.exteriorDamage.map(damage => damage) : [],
        interiorDamagesStatus: adData?.vehicle_details?.interiorDamage?.length > 0 ? "yes" : "no",
        interiorDamage: adData?.vehicle_details?.interiorDamage ? adData.vehicle_details.interiorDamage.map(damage => damage) : [],
        mechanicalIssuesStatus: adData?.vehicle_details?.mechanicalIssues?.length > 0 ? "yes" : "no",
        mechanicalIssues: adData?.vehicle_details?.mechanicalIssues ? adData.vehicle_details.mechanicalIssues.map(issues => issues) : [],
        modificationStatus: adData?.vehicle_details?.modifications ? "yes" : "no",
        modification: adData?.vehicle_details?.modifications || "",
        keyFobs: adData?.vehicle_details?.keyFobs || "",
        tireCondition: adData?.vehicle_details?.tireCondition || "",
        conditionRating: adData?.vehicle_details?.condition || "",
        features: adData.features ? adData.features.map(feature => feature.id) : [],
        accidentHistory: {
            count: adData?.vehicle_details?.accidentHistory?.count || '',
            severity: adData?.vehicle_details?.accidentHistory?.severity || '',
        },
    } : {
        files: [], reservedBid: '', modelYear: '', make: '', model: '', transmission: '', engineType: '', bodyType: '', mileage: '',
        location: '', engineCapacity: '', seatCapacity: '', description: '', features: [], exteriorColor: '', interiorColor: '',
        loanOrLeaseStatus: '', exteriorDamagesStatus: '', exteriorDamage: [], interiorDamagesStatus: '', interiorDamage: [],
        mechanicalIssuesStatus: '', mechanicalIssues: [], modification: '', modificationStatus: '', keyFobs: '', startingBid: '',
        tireCondition: '', conditionRating: '', driversLicense: [], vehicleRegistration: [], companyName: '', dueAmount: '',
        accidentHistory: {
            count: '',
            severity: '',
        }
    };
    // console.log('initialValues', initialValues)

    async function fetchImageAsFile(url) {
        try {
            const response = await uploadFile(url);

            if (response.data && response.data.fileContent) {
                const { originalname, mimetype, fileContent } = response.data;
                const binaryFile = base64ToFile(fileContent, originalname, mimetype);
                return binaryFile;
            }
        } catch (error) {
            console.error("Error fetching file:", error);
            throw error;
        }
    }


    // Convert Base64 to a binary File object
    function base64ToFile(base64Data, fileName, mimeType) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i += 512) {
            const slice = byteCharacters.slice(i, i + 512);
            const byteNumbers = new Array(slice.length);
            for (let j = 0; j < slice.length; j++) {
                byteNumbers[j] = slice.charCodeAt(j);
            }
            byteArrays.push(new Uint8Array(byteNumbers));
        }
        return new File(byteArrays, fileName, { type: mimeType });
    }


    async function appendFilesToFormData(formData, key, files) {
        if (!Array.isArray(files)) {
            console.error(`${key} should be an array!`);
            return;
        }

        for (const file of files) {
            if (typeof file === "string") {
                try {
                    const binaryFile = await fetchImageAsFile(file);
                    formData.append(key, binaryFile);
                } catch (error) {
                    console.error(`Error converting ${key} URL to binary file:`, error);
                }
            } else if (file instanceof File) {
                formData.append(key, file);
            } else {
                console.warn(`Unexpected format for ${key}:`, file);
            }
        }
    }

    const handleEditAd = async (values) => {
        console.log("Values", values);
        const formData = new FormData();

        // Add form data fields first
        formData.append("reservedPrice", parseFloat(values.reservedBid));
        formData.append("modelYear", values.modelYear);
        formData.append("make", values.make);
        formData.append("model", values.model);
        formData.append("transmissionType", values.transmission);
        formData.append("engineType", values.engineType);
        formData.append("bodyType", parseInt(values.bodyType));
        formData.append("mileage", parseFloat(values.mileage));
        formData.append("location", values.location);
        formData.append("engineCapacity", parseFloat(values.engineCapacity));
        formData.append("seatCapacity", values.seatCapacity);
        formData.append("description", values.description);
        formData.append("featureIds", JSON.stringify(values.features));
        formData.append("startingBid", parseFloat(values.startingBid));
        formData.append("exteriorColor", values.exteriorColor);
        formData.append("interiorColor", values.interiorColor);
        formData.append("loanOrLeaseStatus", values.loanOrLeaseStatus);
        if (["loan", "lease"].includes(values.loanOrLeaseStatus)) {
            formData.append("companyName", values.companyName);
            formData.append("dueAmount", parseFloat(values.dueAmount));
        }
        formData.append("condition", values.conditionRating);
        formData.append("exteriorDamage", values.exteriorDamagesStatus === "yes" ? JSON.stringify(values.exteriorDamage) : JSON.stringify([]));
        formData.append("interiorDamage", values.interiorDamagesStatus === "yes" ? JSON.stringify(values.interiorDamage) : JSON.stringify([]));
        formData.append("mechanicalIssues", values.mechanicalIssuesStatus === "yes" ? JSON.stringify(values.mechanicalIssues) : JSON.stringify([]));
        formData.append("modifications", values.modificationStatus === "yes" ? values.modification : "");
        formData.append("keyFobs", values.keyFobs);
        formData.append("tireCondition", values.tireCondition);

        // await appendFilesToFormData(formData, "files", values.files);

        const groupAndAppendFiles = async (key, files) => {
            const groupedFiles = files.reduce((acc, file) => {
                if (!acc[file.type]) {
                    acc[file.type] = [];
                }
                acc[file.type].push(file.url);
                return acc;
            }, {});

            for (const [type, urls] of Object.entries(groupedFiles)) {
                const groupKey = `${type}Images`;
                for (const url of urls) {
                    try {
                        const binaryFile = await fetchImageAsFile(url);
                        formData.append(groupKey, binaryFile);
                    } catch (error) {
                        console.error(`Error converting ${groupKey} URL to binary file:`, error);
                    }
                }
            }
        };

        await groupAndAppendFiles("files", values.files);

        await appendFilesToFormData(formData, "vehicleRegistration", values.vehicleRegistration);
        await appendFilesToFormData(formData, "driversLicense", values.driversLicense);
        values.accidentHistory.severity = values.accidentHistory.count === "0" ? "" : values.accidentHistory.severity;
        formData.append("accidentHistory", JSON.stringify(values.accidentHistory));

        try {
            await Toaster.handleApiCall(() => updateAd(listingId, formData),
                "Form submitted successfully.",
                (response) => {
                    navigate('/ads')
                }
            );
        } catch (error) {
            console.error("Error submitting the form:", error);
        }
    };

    const fetchModel = async (makeId) => {
        try {
            console.log("makeId", makeId)
            const data = await getModelByMakeId(makeId);
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name
            }));
            setModelptions(options);
        } catch (error) {
            console.error("Error fetching model data:", error);
        }
    };

    const fetchOptions = async (fetchFunction, setOptions, errorMsg) => {
        try {
            const data = await fetchFunction();
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setOptions(options);
        } catch (error) {
            console.error(`Error fetching ${errorMsg} data:`, error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await viewAd(listingId);
            console.log("ViewAd API", response.data);
            const data = response?.data || {};
            if (data?.length <= 0) {
                navigate('/404')
            }
            setAdData(data);

            if (data.makes?.id) {
                fetchModel(data.makes.id);
            }
        } catch (error) {
            console.error("Error fetching ad details:", error);
        }
    };

    const modelYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 1990;
        const options = [];

        for (let year = currentYear; year >= startYear; year--) {
            options.push({ value: year, label: year.toString() });
        }

        return options;
    }

    useEffect(() => {
        if (listingId) {
            console.log("listingId", listingId)
            fetchData();
        }
    }, [listingId])

    useEffect(() => {
        fetchOptions(getMake, setMakeOptions, "make");
        fetchOptions(getTransmissionTypes, setTransmissionOptions, "transmission");
        fetchOptions(getBodyTypes, setBodyTypeOptions, "body type");
        fetchOptions(getEngineTypes, setEngineTypeOptions, "engine type");
        fetchOptions(getFeatures, setFeaturesOptions, "features");
    }, []);

    return (
        <>
            <Container >
                <Grid container sx={{ width: "100%" }}>
                    <Grid size={{ xs: 12 }} sx={{ width: "100%" }}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            //   validateOnMount={false}
                            onSubmit={handleEditAd}
                        >

                            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, validateForm, touched, errors, isValid, dirty }) => (
                                <form onSubmit={handleSubmit} autoComplete="off" autoCapitalize="off">
                                    <Stack sx={{ boxShadow: "0px 1px 7px 0px #D1D1D1", borderTop: "3px solid #22B14B", padding: { xs: "10px", md: "40px" } }}>
                                        <Stack
                                            spacing={2}
                                            sx={{ marginTop: { xs: "30px" } }}
                                        >

                                            <ImageUploadSection
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                touched={touched}
                                                disabled={true}
                                            />

                                            <VehicleDetailsSection
                                                values={values}
                                                handleChange={handleChange}
                                                errors={errors}
                                                setFieldValue={setFieldValue}
                                                fetchModel={fetchModel}
                                                makeOptions={makeOptions}
                                                modelOptions={modelOptions}
                                                transmissionOptions={transmissionOptions}
                                                engineTypeOptions={engineTypeOptions}
                                                bodyTypeOptions={bodyTypeOptions}
                                                modelYearOptions={modelYearOptions()}
                                                Options={Options}
                                            />

                                            <VehicleConditionSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                            />

                                            <Typography variant="p1">Features</Typography>
                                            <Box>
                                                {/* <Stack direction={'row'} flexWrap={'wrap'} width={'50%'}> */}
                                                <Stack
                                                    direction={{ xs: 'column', md: 'row' }}
                                                    flexWrap="wrap"
                                                    sx={{ width: { xs: '100%', md: '60%' } }}
                                                >
                                                    <Checkbox
                                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                                        options={featuresOptions}
                                                        selectedValues={values.features}
                                                        onChange={(newFeatures) => setFieldValue("features", newFeatures)}
                                                        error={errors.features && touched.features}
                                                        helperText={errors.features && touched.features && values.features.length === 0 ? errors.features : ''}
                                                    />
                                                </Stack>
                                            </Box>

                                            <PricingSection
                                                values={values}
                                                handleChange={handleChange}
                                                errors={errors}
                                            />

                                            <OwnershipVerificationSection
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                touched={touched}
                                            />

                                            <Typography variant="p1">Description</Typography>
                                            <TextInput
                                                label="Write description about your car"
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                fullWidth
                                                multiline
                                                // minRows="4"
                                                rows={5}
                                            />
                                        </Stack>
                                    </Stack>

                                    <Box display="flex" alignItems="center" border="1px solid" borderColor="#3FBE61" bgcolor="#EBF7EE" borderRadius="8px" padding={2} mt={5}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <InfoIcon color="success" fontSize="large" />

                                            <Stack>
                                                <Typography fontWeight="bold" color="text.primary">
                                                    Note:
                                                </Typography>
                                                <Typography variant="h10" color="third.primary">
                                                    All auctions are set to a <Typography component="span" color="success.main" fontWeight="bold">2-hour</Typography> duration. Listings require up to <Typography component="span" color="success.main" fontWeight="bold">24 hours</Typography> for verification before going live; you'll receive the notification once approved.
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>


                                    <Stack mt={4} flexDirection={"row"} justifyContent="flex-end" gap={'20px'}>
                                        <Button
                                            variant="outlined"
                                            color="transparent"
                                            sx={{ width: '140px', }}
                                            onClick={handleBackClick}
                                        >
                                            Back
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            // fullWidth
                                            // disabled={!(isValid && dirty)}
                                            sx={{ width: '230px', textTransform: 'capitalize' }}
                                            onClick={async (e) => {
                                                const validationErrors = await validateForm();
                                                if (Object.keys(validationErrors).length > 0) {
                                                    toast.dismiss()
                                                    // toast.error('Please fill out all required fields');
                                                    toast.error('Submission error. Please review the form.',
                                                        {
                                                            style: theme.toast.style,
                                                        });
                                                }
                                            }}
                                        >
                                            Save Changes
                                        </Button>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default EditAd
