import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import { NOTIFICATION_ICONS } from "../../../../constants/notificationIcon";
import Loader from "../../../Loader";

const NotificationMenu = ({ notifications, loading, handleNotificationClick, fetchMore, hasMore }) => (
    <Box overflow={'auto'} padding={2} sx={{ width: { xs: 250, sm: 480 } }}>
        <Typography variant="h6" gutterBottom>
            Notifications
        </Typography>

        <Stack spacing={2} mt={5}>
            {notifications.map((notification) => {
                const iconSrc = NOTIFICATION_ICONS[notification.type];
                return (
                    <Box
                        key={notification.id}
                        onClick={() => handleNotificationClick(notification.entityId, notification.type)}
                        p={2}
                        borderRadius={2}
                        boxShadow={1}
                        backgroundColor="background.paper"
                        display="flex"
                        alignItems="center"
                        gap={2}
                        sx={{ cursor: "pointer", "&:hover": { backgroundColor: "action.hover" } }}
                    >
                        {iconSrc && (
                            <img
                                src={iconSrc}
                                alt={`${notification.type} icon`}
                                style={{ width: 32, height: 32, borderRadius: "50%" }}
                            />
                        )}
                        <Box>
                            <Typography variant="body2" fontSize={16}>{notification.title}</Typography>
                            <Typography variant="body2" color="text.secondary">{notification.message}</Typography>
                        </Box>
                    </Box>
                );
            })}
        </Stack>

        {hasMore && (
            <Box mt={4} display="flex" justifyContent="center">
                <Button variant="outlinedNotification" onClick={fetchMore} disabled={loading} sx={{ textTransform: "capitalize", height: "fit-content" }}>
                    {loading ? <Loader /> : "View More"}
                    {/* View More */}
                </Button>
            </Box>
        )}
        {!hasMore && notifications.length > 0 && (
            <Box mt={5} textAlign="center">
                <Typography variant="body1">No more notifications</Typography>
            </Box>
        )}
        {notifications.length === 0 && !loading && (
            <Box mt={2} textAlign="center">
                <Typography variant="body1">No new notifications</Typography>
            </Box>
        )}
    </Box>
);

export default NotificationMenu;
