import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../context/AuthContext';
import { fetchAd } from "../../../api/services/consumerService";
import { fetchAuction } from "../../../api/services/dealerService";
import HeroSection from './HeroSection';
import AdvertisementSection from './AdvertisementSection';
import Loader from "../../../components/Loader";
import SignupSection from './SignupSection';
import TestimonialsSection from './TestimonialSection';
import MobileSection from './MobileSection';

const HomePage = () => {
    const [adData, setAdData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { user } = useAuth();
    const isAdmin = user?.roleName === "admin";

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let response;
                response = await fetchAuction();
                if (user?.roleName === "consumer") {
                    response = await fetchAd();
                }
                const data = response?.data || [];
                setAdData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user?.roleName]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <HeroSection />
            {loading ? (
                <Loader />
            ) : (
                <>
                    {adData?.length > 0 && !isAdmin && (
                        <AdvertisementSection adData={adData} role={user?.roleName} />
                    )}
                    {!isAdmin && (
                        <>
                            <SignupSection />
                            <TestimonialsSection />
                            <MobileSection />
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default HomePage;
