import React, { useState, useEffect } from "react";
import { Box, Typography, Card, Rating, IconButton, Grid2 as Grid, Button, Stack, Slide } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Image from "../../../assets/images/SignUpImage.png";
import { useSwipeable } from "react-swipeable";
import testimonialsData from "./data.json";

const TestimonialCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState("left");
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        setTestimonials(testimonialsData);
    }, []);

    const handlePrevious = () => {
        setDirection("right");
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? Math.ceil(testimonials.length / 2) - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setDirection("left");
        setCurrentIndex((prevIndex) =>
            (prevIndex + 1) % Math.ceil(testimonials.length / 2)
        );
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrevious,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    return (
        <Box
            maxWidth={"auto"} margin={"auto"} textAlign={"center"}
            sx={{ py: { xs: 6, md: 12 } }}
            {...swipeHandlers}
        >
            <Button
                variant="containedStatus"
                sx={{
                    fontSize: "14px",
                    backgroundColor: "background.second",
                    fontWeight: "bold"
                }}
            >
                TESTIMONIALS
            </Button>
            <Typography variant="h4" sx={{ fontWeight: "bold", my: 4, px: 1 }}>
                What people say about us?
            </Typography>

            <Box position={"relative"} overflow={"hidden"} padding={1}>
                <Box position="relative" minHeight="400px">
                    {testimonials.length > 0 &&
                        Array.from({ length: Math.ceil(testimonials.length / 2) }, (_, groupIndex) => (
                            <Slide
                                key={groupIndex}
                                direction={direction}
                                in={groupIndex === currentIndex}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Box
                                    position="absolute"
                                    width="100%"
                                    height="100%"
                                    display="flex"
                                    justifyContent="center"
                                    gap={4}
                                    sx={{ pointerEvents: "none" }}
                                >
                                    {testimonials.slice(groupIndex * 2, groupIndex * 2 + 2).map((testimonial, index) => (
                                        <Card
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                borderRadius: "16px",
                                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                                maxWidth: "800px",
                                                height: "100%"
                                            }}
                                        >
                                            <Grid container spacing={0} alignItems={"center"} flexWrap={"nowrap"}>
                                                <Grid md={6} sx={{ display: { xs: "none", md: "flex" } }}>
                                                    <Box
                                                        component="img"
                                                        src={Image}
                                                        alt={testimonial.name}
                                                        sx={{
                                                            width: "100%",
                                                            minHeight: "400px",
                                                            borderRadius: "16px 0px 0px 16px"
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid xs={12} md={6} sx={{ padding: 2, textAlign: "left" }}>
                                                    <Stack spacing={2}>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{ fontWeight: "bold", mr: 1 }}
                                                        >
                                                            {testimonial.stars}
                                                        </Typography>
                                                        <Rating
                                                            value={testimonial.stars}
                                                            readOnly
                                                            precision={0.5}
                                                        />
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontStyle: "italic",
                                                                color: "#666666",
                                                                mb: 2
                                                            }}
                                                        >
                                                            “{testimonial.feedback}”
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ fontWeight: "bold" }}
                                                        >
                                                            {testimonial.name}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ color: "#888888" }}>
                                                            From {testimonial.location}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    ))}
                                </Box>
                            </Slide>
                        ))}
                </Box>

                <IconButton
                    onClick={handlePrevious}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "16px",
                        transform: "translateY(-50%)",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        color: "white",
                        zIndex: 1,
                        "&:hover": { backgroundColor: "rgba(0,0,0,0.3)" },
                        display: { xs: "none", md: "flex" }
                    }}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>

                <IconButton
                    onClick={handleNext}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        right: "16px",
                        transform: "translateY(-50%)",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        color: "white",
                        zIndex: 1,
                        "&:hover": { backgroundColor: "rgba(0,0,0,0.3)" },
                        display: { xs: "none", md: "flex" }
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </Box >
    );
};

export default TestimonialCarousel;
