import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import HomePage from '../screens/Common/HomePage';
import Login from '../screens/Auth/LoginScreen';
import Signup from '../screens/Auth/SignUpScreen';
import Forgot from '../screens/Auth/ForgotScreen';
import Confirm from '../screens/Auth/ConfirmScreen';
import Reset from '../screens/Auth/ResetScreen';
import Profile from '../screens/Common/ProfileScreen';
import MyAds from "../screens/Consumer/MyAds"
import ViewAd from "../screens/Consumer/ViewAd"
import CreateAd from '../screens/Consumer/CreateAd';
import EditAd from '../screens/Consumer/EditAd'
import AdDetail from '../screens/Dealer/AdDetail';
import Auctions from '../screens/Dealer/Auctions';
import MyBids from '../screens/Dealer/MyBids';
import NotFound from '../screens/Common/NotFound';
import { useAuth } from '../context/AuthContext';
import MainLayout from '../layouts';
import PrivateLayout from '../layouts/PrivateLayout';
import PublicLayout from '../layouts/PublicLayout';
import AdminUsersPage from '../screens/Admin/User';
import AllAds from '../screens/Admin/Ads';
import Approval from "../screens/Admin/ViewAd"
import InspectionForm from '../screens/Consumer/InspectionForm';
import AdminInspectionForm from '../screens/Admin/InspectionForm'
import AuthLayout from '../layouts/AuthLayout';
import About from '../screens/Common/About';

const Routes = () => {
  const { loadingAuth, isAuthenticated } = useAuth();

  const routes = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: <PublicLayout />,
          children: [
            { path: '', element: <HomePage /> },
            { path: '/about', element: <About /> },
          ],
        },
        {
          path: '',
          element: <AuthLayout />,
          children: [
            { path: 'login', element: isAuthenticated ? <Navigate to="/" /> : <Login /> },
            { path: 'signup', element: isAuthenticated ? <Navigate to="/" /> : <Signup /> },
            { path: 'forgotpassword', element: <Forgot /> },
            { path: 'confirmpassword', element: <Confirm /> },
            { path: 'resetpassword/:token', element: <Reset /> },
          ],
        },
        {
          path: '/ads',
          element: <PrivateLayout role="consumer" />,
          children: [
            { path: '', element: <MyAds /> },
            { path: 'create', element: <CreateAd /> },
            { path: ':id', element: <ViewAd /> },
            { path: 'edit/:listingId', element: <EditAd /> },
            { path: 'inspection/:listingId', element: <InspectionForm /> },
          ],
        },
        {
          path: '/auctions',
          element: <PrivateLayout role="dealer" />,
          children: [
            // Dealer-specific routes
            { path: '', element: <Auctions /> },
            { path: ':id', element: <AdDetail /> },
            // { path: ':id', element: <AdDetail /> }, // Uncomment and use correct imports

          ],
        },
        {
          path: '/bids',
          element: <PrivateLayout role="dealer" />,
          children: [
            // Dealer-specific routes
            { path: '', element: <MyBids /> },
            { path: ':id', element: <AdDetail /> },
          ],
        },
        {
          path: '/profile',
          element: <PrivateLayout />,
          children: [
            { path: '', element: <Profile /> },
          ],
        },
        {
          path: '/admin',
          element: <PrivateLayout role="admin" />,
          children: [
            { path: '', element: <AdminUsersPage /> },
            { path: 'users', element: <AdminUsersPage /> },
            { path: 'ads', element: <AllAds /> },
            { path: 'ads/:listingId', element: <Approval /> },
            { path: 'ads/inspection/:inspectionId', element: <AdminInspectionForm /> },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/404" />
        },
        {
          path: '/404',
          element: <NotFound />
        },
      ],
    },
  ]);

  if (loadingAuth) {
    return null;
  }

  return routes;
};

export default Routes;