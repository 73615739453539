import * as React from "react";
import { Formik } from 'formik';
import Options from './data.json'
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { viewAd } from "../../../api/services/consumerService";
import { updateAdStatus } from "../../../api/services/adminService";
import { Stack, Box, Typography, Container, Grid2 as Grid } from "@mui/material";
import { Button, TextInput, Checkbox, Toaster, Modal } from "../../../components";
import { getMake, getModelByMakeId, getTransmissionTypes, getBodyTypes, getEngineTypes, getFeatures } from "../../../api/services/listingService";
import { ImageUploadSection, PricingSection, OwnershipVerificationSection, VehicleDetailsSection, VehicleConditionSection } from '../../../components/Form';
import validationSchema from './ValidationSchema';

const ViewAd = () => {
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelptions] = useState([]);
    const [transmissionOptions, setTransmissionOptions] = useState([]);
    const [bodyTypeOptions, setBodyTypeOptions] = useState([]);
    const [engineTypeOptions, setEngineTypeOptions] = useState([]);
    const [featuresOptions, setFeaturesOptions] = useState([]);
    const [adData, setAdData] = useState(null);
    const { listingId } = useParams();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [reason, setReason] = useState('');

    const handleRejectAd = async () => {
        const status = "rejected"
        await Toaster.handleApiCall(() => updateAdStatus(listingId, status, reason),
            "Ad Approved!",
            (response) => {
                navigate("/admin/ads");
            }
        );
        setModalOpen(false); // Close the modal after submission
        setReason('');
    };

    const initialValues = adData ? {
        files: adData?.listing_images || [],
        vehicleRegistration: adData?.vehicle_details?.vehicleRegistration ? [adData?.vehicle_details?.vehicleRegistration] : [],
        driversLicense: adData?.driversLicense ? [adData?.driversLicense] : [],
        reservedBid: adData?.reservedPrice || '',
        startingBid: adData?.startingBid || '',
        modelYear: adData?.vehicle_details?.modelYear || '',
        make: adData?.makes?.id || '',
        model: adData?.models?.id || '',
        transmission: adData?.transmission_types?.id || '',
        engineType: adData?.engine_types?.id || '',
        bodyType: adData?.body_types?.id || '',
        mileage: adData?.vehicle_details?.mileage || '',
        location: adData?.location || '',
        engineCapacity: adData?.vehicle_details?.engineCapacity || '',
        seatCapacity: adData?.vehicle_details?.seatCapacity || '',
        description: adData?.description || '',
        exteriorColor: adData?.vehicle_details?.exteriorColor || '',
        interiorColor: adData?.vehicle_details?.interiorColor || '',
        loanOrLeaseStatus: adData?.vehicle_details?.loanOrLeaseStatus || '',
        companyName: adData?.vehicle_details?.companyName || '',
        dueAmount: adData?.vehicle_details?.dueAmount || '',
        exteriorDamagesStatus: adData?.vehicle_details?.exteriorDamage?.length > 0 ? "yes" : "no",
        exteriorDamage: adData?.vehicle_details?.exteriorDamage ? adData.vehicle_details.exteriorDamage.map(damage => damage) : [],
        interiorDamagesStatus: adData?.vehicle_details?.interiorDamage?.length > 0 ? "yes" : "no",
        interiorDamage: adData?.vehicle_details?.interiorDamage ? adData.vehicle_details.interiorDamage.map(damage => damage) : [],
        mechanicalIssuesStatus: adData?.vehicle_details?.mechanicalIssues?.length > 0 ? "yes" : "no",
        mechanicalIssues: adData?.vehicle_details?.mechanicalIssues ? adData.vehicle_details.mechanicalIssues.map(issues => issues) : [],
        modificationStatus: adData?.vehicle_details?.modifications ? "yes" : "no",
        modification: adData?.vehicle_details?.modifications || "",
        keyFobs: adData?.vehicle_details?.keyFobs || "",
        tireCondition: adData?.vehicle_details?.tireCondition || "",
        conditionRating: adData?.vehicle_details?.condition || "",
        features: adData.features ? adData.features.map(feature => feature.id) : [],
        accidentHistory: {
            count: adData?.vehicle_details?.accidentHistory?.count || '',
            severity: adData?.vehicle_details?.accidentHistory?.severity || '',
        },
    } : {
        files: [], reservedBid: '', modelYear: '', make: '', model: '', transmission: '', engineType: '', bodyType: '', mileage: '',
        location: '', engineCapacity: '', seatCapacity: '', description: '', features: [], exteriorColor: '', interiorColor: '',
        loanOrLeaseStatus: '', exteriorDamagesStatus: '', exteriorDamage: [], interiorDamagesStatus: '', interiorDamage: [],
        mechanicalIssuesStatus: '', mechanicalIssues: [], modification: '', modificationStatus: '', keyFobs: '', startingBid: '',
        tireCondition: '', conditionRating: '', driversLicense: [], vehicleRegistration: [], companyName: '', dueAmount: '',
        accidentHistory: {
            count: '',
            severity: '',
        }
    };

    const handleApproveAd = async () => {
        // console.log('values', values)

        const status = "approved"
        await Toaster.handleApiCall(() => updateAdStatus(listingId, status),
            "Ad Approved!",
            (response) => {
                navigate("/admin/ads");
            }
        );
    }


    const fetchModel = async (makeId) => {
        try {
            // console.log("makeId", makeId)
            const data = await getModelByMakeId(makeId);
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name
            }));
            setModelptions(options);
        } catch (error) {
            console.error("Error fetching model data:", error);
        }
    };

    const fetchOptions = async (fetchFunction, setOptions, errorMsg) => {
        try {
            const data = await fetchFunction();
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setOptions(options);
        } catch (error) {
            console.error(`Error fetching ${errorMsg} data:`, error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await viewAd(listingId);
            console.log("ViewAd API", response.data);
            const data = response?.data || {};
            if (data?.length <= 0) {
                navigate('/404')
            }
            setAdData(data);

            if (data.makes?.id) {
                fetchModel(data.makes.id);
            }
        } catch (error) {
            console.error("Error fetching ad details:", error);
            navigate('/404')
        }
    };
    const modelYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 1990;
        const options = [];

        for (let year = currentYear; year >= startYear; year--) {
            options.push({ value: year, label: year.toString() });
        }

        return options;
    }

    useEffect(() => {
        if (listingId) {
            // console.log("listingId", listingId)
            fetchData();
        }
    }, [listingId])

    useEffect(() => {
        fetchOptions(getMake, setMakeOptions, "make");
        fetchOptions(getTransmissionTypes, setTransmissionOptions, "transmission");
        fetchOptions(getBodyTypes, setBodyTypeOptions, "body type");
        fetchOptions(getEngineTypes, setEngineTypeOptions, "engine type");
        fetchOptions(getFeatures, setFeaturesOptions, "features");
    }, []);

    return (
        <>
            <Container >
                <Grid container sx={{ width: "100%" }}>
                    <Grid size={{ xs: 12 }} sx={{ width: "100%" }}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            //   validateOnMount={false}
                            onSubmit={handleApproveAd}
                        >

                            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors, isValid, dirty }) => (
                                <form onSubmit={handleSubmit} autoComplete="off" autoCapitalize="off">
                                    <Stack sx={{ boxShadow: "0px 1px 7px 0px #D1D1D1", borderTop: "3px solid #22B14B", padding: { xs: "10px", md: "40px" } }}>
                                        <Stack
                                            spacing={2}
                                            sx={{ marginTop: { xs: "30px" } }}
                                        >

                                            <ImageUploadSection
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                touched={touched}
                                                disabled={true}
                                            />

                                            <VehicleDetailsSection
                                                values={values}
                                                handleChange={handleChange}
                                                errors={errors}
                                                setFieldValue={setFieldValue}
                                                fetchModel={fetchModel}
                                                makeOptions={makeOptions}
                                                modelOptions={modelOptions}
                                                transmissionOptions={transmissionOptions}
                                                engineTypeOptions={engineTypeOptions}
                                                bodyTypeOptions={bodyTypeOptions}
                                                modelYearOptions={modelYearOptions()}
                                                Options={Options}
                                                disabled={true}
                                            />

                                            <VehicleConditionSection
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                Options={Options}
                                                disabled={true}
                                            />

                                            <Typography variant="p1">Features</Typography>
                                            <Box>
                                                {/* <Stack direction={'row'} flexWrap={'wrap'} width={'50%'}> */}
                                                <Stack
                                                    direction={{ xs: 'column', md: 'row' }}
                                                    flexWrap="wrap"
                                                    sx={{ width: { xs: '100%', md: '60%' } }}
                                                >
                                                    <Checkbox
                                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                                        options={featuresOptions}
                                                        selectedValues={values.features}
                                                        onChange={(newFeatures) => setFieldValue("features", newFeatures)}
                                                        error={errors.features && touched.features}
                                                        helperText={errors.features && touched.features && values.features.length === 0 ? errors.features : ''}
                                                        disabled={true}
                                                    />
                                                </Stack>
                                            </Box>

                                            <PricingSection
                                                values={values}
                                                handleChange={handleChange}
                                                errors={errors}
                                                disabled={true}
                                            />

                                            <OwnershipVerificationSection
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                touched={touched}
                                                disabled={true}
                                            />

                                            <Typography variant="p1">Description</Typography>
                                            <TextInput
                                                label="Write description about your car"
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                fullWidth
                                                multiline
                                                // minRows="4"
                                                rows={5}
                                                disabled
                                            />
                                        </Stack>
                                    </Stack>

                                    {adData && adData?.status === "pending" && (
                                        <Stack mt={4} flexDirection={"row"} justifyContent="flex-end" gap={'20px'}>
                                            <Button
                                                variant="containedReject"
                                                sx={{ width: '230px', textTransform: 'capitalize' }}
                                                // onClick={handleRejectAd}
                                                onClick={() => setModalOpen(true)}
                                            >
                                                Reject
                                            </Button>

                                            <Button
                                                type="submit"
                                                variant="containedPrimary"
                                                // color="primary"
                                                sx={{ width: '230px', textTransform: 'capitalize' }}
                                            >
                                                Approve
                                            </Button>
                                        </Stack>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                width={{ xs: '90%', sm: '50%', md: '40%', lg: '30%' }}
            >
                <Stack mt={'10%'}>
                    <TextInput
                        name={reason}
                        value={reason}
                        label="Write reason for rejection"
                        fullWidth
                        multiline
                        // minRows={4}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </Stack>
                <Stack mt={3} flexDirection="row" justifyContent="flex-end" gap="20px">
                    <Button
                        variant="outlinedCancel"
                        onClick={() => setModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="containedReject"
                        color="error"
                        sx={{ width: '200px' }}
                        onClick={handleRejectAd}
                        disabled={!reason.trim()}
                    >
                        Submit
                    </Button>
                </Stack>
            </Modal>
        </>
    )
}

export default ViewAd
