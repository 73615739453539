export const STATUSES = {
    BIDDING_STATUS: {
        ACCEPTED: 'accepted',
        REJECTED: 'rejected',
        NEGOTIATION: 'negotiation',
        OPEN: 'open',
    },

    NEGOTIATION_STATUS: {
        ONGOING: 'ongoing',
        ACCEPTED: 'accepted',
        REJECTED: 'rejected',
    },

    NOTIFICATION_STATUS: {
        UNREAD: 'unread',
        READ: 'read',
        ARCHIVED: 'archived',
    },

    LISTING_STATUS: {
        ACTIVE: 'active',
        EXPIRED: 'expired',
        PENDING: 'pending',
        ACCEPTED: 'accepted',
        APPROVED: 'approved',
        REJECTED: 'rejected',
        SOLD: 'sold',
        ARCHIVED: 'archived',
        DRAFT: 'draft'
    },

    INSPECTION_STATUS: {
        PENDING: 'pending',
        APPROVED: 'approved',
        REJECTED: 'rejected',
    },

    USER_STATUS: {
        ACTIVE: 'active',
        INACTIVE: 'inactive'
    }
};